/*
 * @Author: joyBoy
 * @Date: 2024-01-05 17:45:44
 * @LastEditors: wangweidong wangweidong@hosecloud.com
 * @LastEditTime: 2024-03-15 16:35:43
 * @FilePath: /easo-fe/src/guards/AuthGuard.tsx
 */
import React, { useEffect, ReactNode } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
// store
import { configStore, chargeStore } from '@/store'
// service
import { getUserInfo } from '@/service/app-auth'
// utils
import TRACK from '@/utils/track'

// ---------------------------------------------------------------------
type AuthGuardProps = {
  children: ReactNode
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const setConfig = configStore(state => state.setConfig)
  const jwtToken = query.get('jwtToken') ?? ''
  const platformId = query.get('$platformId') ?? ''
  const source = query.get('source') ?? ''
  const taskType = query.get('taskType') ?? ''

  useEffect(() => {
    if (jwtToken) {
      localStorage.setItem('jwtToken', jwtToken)
      axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`
    } else {
      const token = localStorage.getItem('jwtToken')
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      }
    }
    if (platformId) {
      setConfig('$platformId', platformId)
      localStorage.setItem('$platformId', platformId)
    } else {
      const id = localStorage.getItem('$platformId')
      if (id) {
        setConfig('$platformId', id)
      }
    }

    // 添加platformId
    axios.defaults.headers.common['platformId'] = configStore.getState().$platformId

    if (source) {
      setConfig('source', source)
    }

    if (taskType) {
      setConfig('taskType', taskType)
    }

    // 获取按量计费信息
    chargeStore.getState().fetch()

    // 获取用户信息
    getUserInfo()
      .then(res => {
        localStorage.setItem('staffId', res.staffId)
        window.TRACK = new TRACK(res)
      })
      .catch(() => {
        console.group('获取用户信息失败')
        console.error('埋点失败')
        console.groupEnd()
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtToken, platformId, setConfig, source])

  return <>{children}</>
}
