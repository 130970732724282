import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import PageLoading from '@/components/base/page-loading'
import AuthGuard from './guards/AuthGuard'

// 实例化存储
import '@/utils/store'

const InnerLayout = React.lazy(() => import(/* webpackChunkName:"inner-layout" */ '@/layouts/inner-layout'))

// ---------------------------------------------------------------------
const App: React.FC = () => {
  return (
    <Router>
      <React.Suspense fallback={<PageLoading />}>
        <AuthGuard>
          <Switch>
            <Route path="/" component={InnerLayout} />
          </Switch>
        </AuthGuard>
      </React.Suspense>
    </Router>
  )
}

export default App
