import { cloneDeep, get, set, unset } from 'lodash'
import { nanoid } from 'nanoid'
// interface
import { WorkflowContext, Choose, IWorkflow } from '@/interface/workflow'
// utils
import { flattenObject } from './utils'
// ----------------------------------------------------------------------

// 将上下文树形菜单递归为一维数组(广度优先)
export const flattenContext = (context: WorkflowContext) => {
  if (!context?.choose) {
    return context
  }

  const { items } = context.choose
  const result: Choose[] = []

  const loop = (list: Choose[]) => {
    const nextList: Choose[] = []
    list.forEach(item => {
      result.push(item)
      if (item.subChoose) {
        nextList.push(
          ...item.subChoose.items.map(subItem => ({
            ...subItem,
            code: `${item.code}.${subItem.code}`,
            label: `${item.label}.${subItem.label}`
          }))
        )
        delete item.subChoose
      }
    })
    if (nextList.length) {
      loop(nextList)
    }
  }

  loop(items)

  context.choose.items = result
  return context
}

// ----------------------------------------------------------------------
//  复制工作流过程处理
export const copyPipeFitter = (workflow: IWorkflow) => {
  const newWorkflow = cloneDeep(workflow)

  // 当前工作流的触发器是webhook，需要将webhook的 urlPath 重置
  if (newWorkflow?.tasks?.[0]?.type === 'webhook') {
    newWorkflow.tasks[0].urlPath = nanoid()
    newWorkflow.tasks[0]?.sampleId && delete newWorkflow.tasks[0].sampleId
  }

  return newWorkflow
}

// ----------------------------------------------------------------------
// 保存值的时候需要把快照解析出来
export const parseSnapshot = task => {
  const flattenTask = flattenObject(task)
  const includesSnapshotKey = Object.keys(flattenTask).filter(
    key => typeof flattenTask[key] === 'string' && flattenTask[key].includes('snapshot')
  )

  const _task = cloneDeep(task)

  includesSnapshotKey.forEach(key => {
    const lodashKey = key.split('.')
    const value = get(_task, lodashKey)
    const lastKey = lodashKey.pop()
    const { value: newValue, snapshot } = JSON.parse(value)
    set(_task, [...lodashKey, lastKey], newValue)
    set(_task, [...lodashKey, `${lastKey}-snapshot`], JSON.stringify(snapshot))
  })

  return _task
}
// ----------------------------------------------------------------------
// 保存值的时候需要把快照存储在 $snapshot 中
export const saveSnapshot = workflow => {
  const flattenWorkflow = flattenObject(workflow)
  const includesSnapshotKey = Object.keys(flattenWorkflow).filter(key => key.includes('-snapshot'))

  const $snapshot = [] as { key: string; value: string }[]

  includesSnapshotKey.forEach(key => {
    const lodashKey = key.split('.')
    const snapshot = get(workflow, lodashKey)
    $snapshot.push({ key: lodashKey.join('.'), value: snapshot })
    unset(workflow, lodashKey)
  })

  workflow.$snapshot = $snapshot
}

// ----------------------------------------------------------------------
// 还原快照
export const restoreSnapshot = workflow => {
  const { $snapshot } = workflow

  if (!$snapshot?.length) {
    return
  }

  $snapshot.forEach(({ key, value }) => {
    set(workflow, key.split('.'), value)
  })

  delete workflow.$snapshot
}

// ----------------------------------------------------------------------
// 将快照值转换为字符串
export const stringifySnapshot = task => {
  const flattenTask = flattenObject(task)
  const includesSnapshotKey = Object.keys(flattenTask).filter(key => key.includes('-snapshot'))
  includesSnapshotKey.forEach(key => {
    const lodashKey = key.split('.')
    const snapshot = get(task, lodashKey)
    const lastKey = lodashKey.pop()
    const valueKey = lastKey?.replace('-snapshot', '')
    const value = get(task, [...lodashKey, valueKey])

    set(task, [...lodashKey, valueKey], JSON.stringify({ value, snapshot: JSON.parse(snapshot) }))
    unset(task, [...lodashKey, lastKey])
  })

  return task
}
