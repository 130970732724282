import { makeRestApiRequest as API } from '@/utils/helpers/RequestHelper'
import { type StaffOptions } from '@/utils/track'

type IConnector = any

// 获取已配置连接器列表
export function getAuthList(appId: string | number): Promise<IConnector> {
  return API('POST', '/api/easo/corpApp/getAuthList', { appId, size: 100 })
}

// 获取连接器配置
export function getAuthConfig(id: string | number): Promise<IConnector> {
  return API('GET', '/api/easo/app/getInfo', { id })
}

// 保存连接器配置
type SaveAuthConfigParams = {
  appId: string | number
  id?: string | number
  authConfig: {
    reqParam: any
  }
}

export function saveAuthConfig(data: SaveAuthConfigParams): Promise<IConnector> {
  return API('POST', '/api/easo/corpApp/saveAuthInfo', data)
}

export type ChargeInfo = {
  total: number
  used: number
  remaining: number
  hasExpiringOrder: boolean
  expiringDate: number
  showExpiringWarning: boolean
  hasExpiredOrder: boolean
  expiredDate: number
  showExpiredWarning: boolean
  status: 'NOT_ACTIVE' | 'ACTIVED' | 'EXPIRED'
  [key: string]: any
}

// 工作流按量计费配置
export function getEasoCharge(): Promise<ChargeInfo> {
  return API('GET', '/api/easo/charge')
}

// 获取用户信息
export function getUserInfo(): Promise<StaffOptions> {
  return API('GET', '/api/easo/me')
}
