const DEFAULT_CACHE_TIME = import.meta.env.VITE_DEFAULT_CACHE_TIME

// ----------------------------------------------------------------------

class Store {
  readonly #STORE: any = null
  readonly #EXPIRES: number = 0

  constructor(store: any, expires?: number) {
    this.#STORE = store
    this.#EXPIRES = expires ?? +DEFAULT_CACHE_TIME
    window[`DEBUG_${store._config.name}`] = this.#STORE
  }

  public async getItem<T>(key: string): Promise<T | null> {
    const res = await this.#STORE.getItem(key)

    if (res?.expires && res.expires > +new Date()) {
      return res.data
    }

    // 如果数据过期，删除数据
    if (res?.expires && res.expires < +new Date()) {
      await this.#STORE.removeItem(key)
    }

    return null
  }

  public async setItem(key: string, value: any) {
    return await this.#STORE.setItem(key, { data: value, expires: +new Date() + this.#EXPIRES })
  }

  public clear() {
    this.#STORE.clear()
  }
}

export default Store
