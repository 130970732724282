import { create } from 'zustand'
import { getEasoCharge, type ChargeInfo } from '@/service/app-auth'

// 工作流按量计费商品状态

interface IStore {
  chargeInfo: ChargeInfo | null
  fetch: () => Promise<any>
}

const chargeStore = create<IStore>(
  (set): IStore => ({
    chargeInfo: null,
    fetch: async () => {
      const res = await getEasoCharge()

      if (!res) {
        return
      }

      set(() => ({
        chargeInfo: res as unknown as ChargeInfo
      }))
    }
  })
)

export default chargeStore
