import { IWorkflow } from '@/interface/workflow'
export const keysMap = {
  clock: ['startAtDate', 'startAtTime', 'mode', 'weekday', 'dayOfMonth', 'triggerTime', 'cron']
}

// ----------------------------------------------------------------------
export function pickKeys(data: IWorkflow): IWorkflow {
  delete data.isDirty
  function deleteRunStatus(tasks) {
    tasks.forEach(task => {
      delete task.runStatus
      if (task?.tasks) {
        deleteRunStatus(task.tasks)
      }
    })
  }

  deleteRunStatus(data.tasks)

  return data
}
