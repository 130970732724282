import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { IWorkflow, WorkflowContext } from '@/interface/workflow'
// services
import { getWorkflowContextDeep, getWorkflowVariables } from '@/service/workflows'
import { setSessionWorkflow } from '@/utils/sessionWorkflow'

interface IState {
  // 数组类型，兼容if和子节点的情况
  taskIndex: number[]
  group: string
  type: string
  workflow: IWorkflow
  workflowContextDeep: WorkflowContext[]
  setTaskIndex: (index: number[]) => void
  setGroup: (group: string) => void
  setType: (type: string) => void
  setWorkflow: (workflow: IWorkflow, isDirty?: boolean) => void
  setContextDeep: (pipelineId?: string) => Promise<void>
}

const useWorkflowStore = create<IState>()(
  devtools((set: any) => ({
    taskIndex: [0],
    group: 'trigger',
    type: '',
    workflow: {
      state: 0, // 默认是停用
      label: '',
      tasks: [] as any[]
    },
    workflowContextDeep: [] as WorkflowContext[],
    // 当前激活的节点下标
    setTaskIndex: (index: number[]) => set({ taskIndex: index }),
    // 当前节点类型分组
    setGroup: (group: string) => set({ group }),
    // 当前节点类型
    setType: (type: string) => set({ type }),
    // 当前工作流
    setWorkflow: (workflow, isDirty = true) => {
      // 排序方法放在这里，防止步骤数字错误
      let _upIndex = 0
      workflow?.tasks?.forEach((item: any, i) => {
        item.orderIndex = _upIndex + 1
        _upIndex = item.orderIndex
        item.levelIndex = [i]
        item?.tasks?.forEach((initem: any, j) => {
          initem.orderIndex = _upIndex + 1
          _upIndex = initem.orderIndex
          initem.levelIndex = [i, j]
          initem?.tasks?.forEach((v: any, k) => {
            v.levelIndex = [i, j, k]
            v.orderIndex = _upIndex + 1
            _upIndex = v.orderIndex
            item.tasksMaxLevel = 3
          })
        })
      })
      set({ workflow: { ...workflow, isDirty } })
      setSessionWorkflow(workflow?.tasks)
    },

    setContextDeep: async (pipelineId?: string) => {
      if (pipelineId) {
        const res = await getWorkflowContextDeep(pipelineId)
        res && set({ workflowContextDeep: res })
      } else {
        const res = await getWorkflowVariables()
        res && set({ workflowContextDeep: res })
      }
    }
  }))
)

export default useWorkflowStore
