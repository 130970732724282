/**
 * @description: 神策埋点
 */

import sa from 'sa-sdk-javascript'

const { origin } = window.location
const IS_PROD = origin.includes('piper.ekuaibao.com')

const SERVER_URL = `https://sensor.ekuaibao.com/sa?project=${IS_PROD ? 'production' : 'default'}`

export type StaffOptions = {
  staffId: string
  name: string
  corporationName: string
  corporationId: string
}

type Options = Record<string, string>

class TRACK {
  STAFF: StaffOptions
  SA = sa

  constructor(staff: StaffOptions) {
    this.STAFF = staff
    this.init(staff)
  }

  init = (staff: StaffOptions) => {
    this.SA.init({
      server_url: SERVER_URL,
      is_track_single_page: true,
      heatmap: { clickmap: 'default', scroll_notice_map: 'default' },
      show_log: false
    })

    this.SA.login(staff.staffId)

    this.SA.setProfile({
      name: staff.name,
      company: staff.corporationName
    })
  }

  track = (key: string, options: Options) => {
    if (!this.STAFF.staffId) {
      return
    }

    const { staffId, name, corporationName, corporationId } = this.STAFF

    this.SA.track(key, {
      staffId,
      corpId: corporationId,
      company: corporationName,
      platForm: 'Web',
      dataSource: 'EKB',
      source: '原生2.0',
      staffName: name,
      ...options
    })
  }
}

export default TRACK
