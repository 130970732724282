import localforage from 'localforage'
import Store from './config-store'

// ----------------------------------------------------------------------
const CACHE_CONFIG_CACHE_TIME = import.meta.env.VITE_CACHE_CONFIG_CACHE_TIME

// 配置缓存默认存储1小时
const config_store = localforage.createInstance({
  name: 'CONFIG_STORE'
})

// 字段缓存默认存储5分钟
const fields_store = localforage.createInstance({
  name: 'FIELDS_STORE'
})

export const CONFIG_STORE = new Store(config_store, +CACHE_CONFIG_CACHE_TIME)
export const FIELDS_STORE = new Store(fields_store)
