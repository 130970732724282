import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

// --------------------------------------------------------------------------

type Key = '$platformId' | 'source' | 'taskType'
export type ChargeInfo = 'loading' | 'succeed' | 'failed'

type Store = {
  $platformId: string
  source: string | null
  taskType: string
  setConfig: (keys: Key, value: string) => void
}

const config = create<Store>()(
  devtools(set => ({
    $platformId: '',
    source: null,
    taskType: '',
    setConfig: (keys: Key, value: string) => set({ [keys]: value })
  }))
)

export default config
