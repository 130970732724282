import { IJob, IResponseJobList } from '@/interface/job'
import { makeRestApiRequest as API } from '@/utils/helpers/RequestHelper'

/**
 * 获取左右工作流
 * @param page 当前页码
 * @param size 每页查询条数
 */
export async function getAllJob(page: number, size: number): Promise<IResponseJobList> {
  return API('GET', '/api/easo/jobs', { number: page, pageSize: size })
}

/**
 * 根据id获取单个Job
 * @param id JobId
 */
export function getJobByID(id: string): Promise<IJob> {
  return API('GET', `/api/easo/jobs/${id}`)
}

export function getJobExecution(
  id: string,
  number: number = 1,
  pageSize: number = 10,
  keywords?: string,
  debugging?: boolean
): Promise<any> {
  return API('GET', `/api/easo/jobs/${id}/execution`, { number, pageSize, keywords, debugging })
}

/**
 * 执行工作流
 * @param pipelineId 工作流id
 */
export function jobRun(
  pipelineId: string,
  autoPublish: boolean,
  entityId?: string,
  dataLimit: number = 20
): Promise<IJob> {
  return API('POST', '/api/easo/jobs', {
    pipelineId,
    inputs: {
      id: entityId,
      $autoPublish: autoPublish
    },
    dataLimit
  })
}

/**
 * 停止工作流执行
 * @param id 工作流id
 */
export function jobStop(id: string): Promise<IJob> {
  return API('PUT', `/api/easo/jobs/${id}/stop`)
}

/**
 * 通过业务对象id获取对应的数据
 * @param id 业务对象id
 */
export function entityData(
  id: string,
  number: number,
  pageSize: number,
  keywords: string,
  billType?: string
): Promise<any> {
  return API('POST', `/api/easo/pipelines/${id}/debugSource`, {
    number,
    pageSize,
    keywords,
    billType
  })
}

/**
 * 工作流执行记录
 * @param id 工作流id
 */
export function jobLogs(
  id: string,
  params: { number: number; pageSize: number; state: string }
): Promise<IResponseJobList> {
  return API('GET', `/api/easo/pipelines/${id}/log`, params)
}

/**
 * 工作流执行记录
 * @param id 工作流id
 */
export function logDetailLogs(id: string): Promise<any> {
  return API('GET', `/api/easo/executions/${id}/logs`)
}

/**
 *根据pipelineId获取当前正在进行中的job
 *@param pipelineId 工作流id
 */
export function getRunningJob(pipelineId: string): Promise<IJob> {
  return API('GET', '/api/easo/jobs/running', { pipelineId: pipelineId, runType: 'DEBUG' })
}
