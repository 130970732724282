import { IWorkflow } from '@/interface/workflow'

const workflowSessionKey = 'temp_workflow'

/**
 * 把当前工作流保存到session
 * @param workflow
 */
export const setSessionWorkflow = (workflow: any) => {
  sessionStorage.setItem(workflowSessionKey, JSON.stringify(workflow))
}

/**
 * 从session获取工作流信息
 */
export const getSessionWorkflow = () => {
  return sessionStorage.getItem(workflowSessionKey)
}

/**
 * 从session删除工作流信息
 */
export const removeSessionWorkflow = () => {
  sessionStorage.removeItem(workflowSessionKey)
}

/**
 * 当前工作流与session工作流做比较
 * 看是否有改动
 * @param workflow
 * @param key
 */
export const compareSessionWorkflow = (workflow: IWorkflow, key: string = workflowSessionKey): boolean => {
  const _temp = sessionStorage.getItem(key)
  return !!(_temp && workflow && JSON.stringify(workflow.tasks).trim() === _temp.trim())
}
