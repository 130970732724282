import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import zh_CN from 'antd/es/locale-provider/zh_CN'
import App from '@/App'
import '@/assets/styles/app.less'
import '@/assets/styles/main.css'
import 'remixicon/fonts/remixicon.css'
import moment from 'moment'
import 'moment/dist/locale/zh-cn'
import '@hose/eui-theme/dist/eui-style.css'

moment.locale('zh-cn')

ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
)
