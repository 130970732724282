import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

/**
 * 操作的时候进行loading效果
 */
interface IStore {
  loading: boolean
  setLoading: (show: boolean) => void
}

const ILoading = create<IStore>()(
  devtools(set => ({
    loading: false,
    setLoading: (loading: boolean) => {
      set({ loading: loading })
    }
  }))
)

export default ILoading
