import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { UpdateFlowDebugState } from '@/service/workflows'
import { getRunningJob } from '@/service/job'

/**
 * 调试窗口的打开与关闭，因为有多个地方使用，所以提出到store中
 */
interface IStore {
  trialVisible: boolean
  trialEntityId: string
  dugSuccess: boolean
  jobStarted: boolean
  jobId: string
  autoPublish: boolean
  setTrialVisible: (show: boolean) => void
  setTrialEntityId: (enId: string) => void
  setDugSuccess: (show: boolean, id?: string, isInit?: boolean) => void
  setJobId: (id: string) => void
  setJobStarted: (state: boolean) => void
  setAutoPublish: (state: boolean) => void
  getJobStatus: (id: string) => Promise<void>
}

const trialVisible = create<IStore>()(
  devtools(set => ({
    trialVisible: false,
    trialEntityId: '',
    dugSuccess: false,
    jobStarted: false,
    jobId: '',
    autoPublish: false,
    setTrialVisible: (show: boolean) => set({ trialVisible: show }),
    setTrialEntityId: (enId: string) => set({ trialEntityId: enId }),
    setDugSuccess: async (success: boolean, id: string = '', isInit: boolean = false) => {
      set({ dugSuccess: success })
      // 如果不是初始化，并且有id的，那么就直接掉接口更新
      if (!isInit && id) {
        await UpdateFlowDebugState(id, success ? 1 : 0)
      }
    },
    setJobId: (id: string) => set({ jobId: id }),
    setJobStarted: (state: any) => set({ jobStarted: state }),
    setAutoPublish: (state: any) => set({ autoPublish: state }),
    // 获取当前运行状态并更新
    getJobStatus: async (id: string) => {
      const res = await getRunningJob(id)
      if (!res) return
      set({
        jobStarted: res?.status === 'STARTED' && res?.runType === 'DEBUG',
        jobId: res?.jobId,
        autoPublish: res?.autoPublish
      })
    }
  }))
)

export default trialVisible
